const descuentos = {
    "Rímac": {
      seguroAnterior: ['no'],
      value: 14,
    },
    "Pacífico": {
      seguroAnterior: ['no', 'no-ahora'],
      value: 20,
    },
  }
export default descuentos