import pacifico from "./individuales/pacifico"
import rimac from "./individuales/rimac"
import mapfre from "./individuales/mapfre"
import sanitas from "./individuales/sanitas"
import laPositiva from "./individuales/laPositiva"
const seguros = [
    pacifico,
    rimac,
    mapfre,
    sanitas,
    laPositiva
]

export default seguros