const mapfre = {
    compañia: 'mapfre',
    gama: {
        'basico': {
            'Mapfre Viva Salud Farmapfre': {
                Titulares: {
                    0: 1958,
                    1: 1958,
                    2: 1958,
                    3: 1958,
                    4: 1958,
                    5: 1958,
                    6: 1958,
                    7: 1958,
                    8: 1958,
                    9: 1958,
                    10: 1958,
                    11: 1958,
                    12: 1958,
                    13: 1958,
                    14: 1958,
                    15: 1958,
                    16: 1958,
                    17: 1958,
                    18: 1958,
                    19: 1958,
                    20: 1958,
                    21: 1958,
                    22: 1958,
                    23: 1958,
                    24: 1958,
                    25: 1958,
                    26: 1958,
                    27: 1958,
                    28: 1958,
                    29: 1958,
                    30: 1958,
                    31: 2441,
                    32: 2441,
                    33: 2441,
                    34: 2441,
                    35: 2441,
                    36: 2604,
                    37: 2618,
                    38: 2631,
                    39: 2644,
                    40: 2656,
                    41: 2788,
                    42: 2813,
                    43: 2915,
                    44: 3023,
                    45: 3133,
                    46: 3546,
                    47: 3631,
                    48: 3721,
                    49: 3812,
                    50: 3905,
                    51: 4340,
                    52: 4815,
                    53: 5315,
                    54: 5838,
                    55: 6385,
                    56: 7344,
                    57: 7584,
                    58: 8092,
                    59: 8358,
                    60: 10458,
                    61: 10795,
                    62: 11144,
                    63: 11502,
                    64: 10122,
                    65: 11875
                }
            },
        },
        'completo': {
            'Mapfre Trebol Farmapfre': {
                Titulares: {
                    0: 3034,
                    1: 3034,
                    2: 3034,
                    3: 3034,
                    4: 3034,
                    5: 3034,
                    6: 3034,
                    7: 3034,
                    8: 3034,
                    9: 3034,
                    10: 3034,
                    11: 3034,
                    12: 3034,
                    13: 3034,
                    14: 3034,
                    15: 3034,
                    16: 3034,
                    17: 3034,
                    18: 3034,
                    19: 3034,
                    20: 3034,
                    21: 3034,
                    22: 3034,
                    23: 3034,
                    24: 3034,
                    25: 3034,
                    26: 3034,
                    27: 3034,
                    28: 3034,
                    29: 3034,
                    30: 3034,
                    31: 3155,
                    32: 3155,
                    33: 3155,
                    34: 3155,
                    35: 3155,
                    36: 3416,
                    37: 3502,
                    38: 3590,
                    39: 3680,
                    40: 3773,
                    41: 4430,
                    42: 4486,
                    43: 4542,
                    44: 4598,
                    45: 4655,
                    46: 4878,
                    47: 4951,
                    48: 5026,
                    49: 5100,
                    50: 5177,
                    51: 6292,
                    52: 6948,
                    53: 7636,
                    54: 8360,
                    55: 9113,
                    56: 10775,
                    57: 11128,
                    58: 11495,
                    59: 11873,
                    60: 12262,
                    61: 16102,
                    62: 16621,
                    63: 17155,
                    64: 17708,
                    65: 18278
                }
            },
        },
    }
}
export default mapfre