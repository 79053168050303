const pacifico = {
    compañia: 'pacifico',
    gama: {
        'superEconomico': {
            'Pacífico Esencial': {
                Titulares: {
                    0: 2094,
                    1: 2094,
                    2: 2094,
                    3: 2094,
                    4: 2094,
                    5: 2094,
                    6: 2094,
                    7: 2094,
                    8: 2094,
                    9: 2094,
                    10: 2094,
                    11: 2094,
                    12: 2094,
                    13: 2094,
                    14: 2094,
                    15: 2094,
                    16: 2094,
                    17: 2094,
                    18: 2094,
                    19: 2094,
                    20: 2094,
                    21: 2094,
                    22: 2094,
                    23: 2094,
                    24: 2094,
                    25: 2094,
                    26: 2214,
                    27: 2214,
                    28: 2214,
                    29: 2214,
                    30: 2214,
                    31: 2453,
                    32: 2453,
                    33: 2453,
                    34: 2453,
                    35: 2453,
                    36: 2573,
                    37: 2573,
                    38: 2573,
                    39: 2573,
                    40: 2573,
                    41: 2752,
                    42: 2932,
                    43: 3052,
                    44: 3171,
                    45: 3291,
                    46: 3411,
                    47: 3530,
                    48: 3650,
                    49: 3830,
                    50: 4009,
                    51: 4189,
                    52: 4548,
                    53: 4907,
                    54: 5266,
                    55: 5625,
                    56: 5984,
                    57: 6402,
                    58: 6821,
                    59: 7240,
                    60: 7599,
                }
            },
            'Pacífico Esencial Plus': {
                Titulares: {
                    0: 2453,
                    1: 2453,
                    2: 2453,
                    3: 2453,
                    4: 2453,
                    5: 2453,
                    6: 2453,
                    7: 2453,
                    8: 2453,
                    9: 2453,
                    10: 2453,
                    11: 2453,
                    12: 2453,
                    13: 2453,
                    14: 2453,
                    15: 2453,
                    16: 2453,
                    17: 2453,
                    18: 2513,
                    19: 2513,
                    20: 2513,
                    21: 2513,
                    22: 2513,
                    23: 2513,
                    24: 2513,
                    25: 2513,
                    26: 2633,
                    27: 2693,
                    28: 2717,
                    29: 2752,
                    30: 2776,
                    31: 2812,
                    32: 2872,
                    33: 2932,
                    34: 2992,
                    35: 3052,
                    36: 3111,
                    37: 3171,
                    38: 3231,
                    39: 3291,
                    40: 3351,
                    41: 3530,
                    42: 3710,
                    43: 3853,
                    44: 3973,
                    45: 4093,
                    46: 4212,
                    47: 4332,
                    48: 4571,
                    49: 4883,
                    50: 5206,
                    51: 5505,
                    52: 5804,
                    53: 6103,
                    54: 6462,
                    55: 6821,
                    56: 7180,
                    57: 7539,
                    58: 8018,
                    59: 8497,
                    60: 10292,
                }
            },
        },
        'economico': {
            'Pacífico Multisalud Base': {
                Titulares: {
                    0: 2932,
                    1: 2932,
                    2: 2932,
                    3: 2932,
                    4: 2932,
                    5: 2932,
                    6: 2932,
                    7: 2932,
                    8: 2932,
                    9: 2932,
                    10: 2932,
                    11: 2932,
                    12: 2932,
                    13: 2932,
                    14: 2932,
                    15: 2932,
                    16: 2932,
                    17: 2932,
                    18: 2992,
                    19: 2992,
                    20: 2992,
                    21: 2992,
                    22: 2992,
                    23: 2992,
                    24: 2992,
                    25: 2992,
                    26: 3076,
                    27: 3135,
                    28: 3171,
                    29: 3255,
                    30: 3291,
                    31: 3351,
                    32: 3411,
                    33: 3470,
                    34: 3530,
                    35: 3590,
                    36: 3650,
                    37: 3710,
                    38: 3770,
                    39: 3830,
                    40: 3949,
                    41: 4069,
                    42: 4368,
                    43: 4667,
                    44: 4966,
                    45: 5266,
                    46: 5565,
                    47: 5744,
                    48: 6103,
                    49: 6462,
                    50: 6821,
                    51: 7180,
                    52: 7539,
                    53: 7898,
                    54: 8257,
                    55: 8616,
                    56: 9095,
                    57: 9693,
                    58: 10292,
                    59: 10890,
                    60: 12087,
                }
            },
        },
        'basico': {
            'Pacífico Red Preferente': {
                Dependientes: {
                    17: 3117,
                    25: 3232,
                    26: 3358
                },
                Titulares: {
                    0: 3209,
                    1: 3209,
                    2: 3209,
                    3: 3209,
                    4: 3209,
                    5: 3209,
                    6: 3209,
                    7: 3209,
                    8: 3209,
                    9: 3209,
                    10: 3209,
                    11: 3209,
                    12: 3209,
                    13: 3209,
                    14: 3209,
                    15: 3209,
                    16: 3209,
                    17: 3209,
                    18: 3289,
                    19: 3289,
                    20: 3289,
                    21: 3289,
                    22: 3289,
                    23: 3289,
                    24: 3289,
                    25: 3289,
                    26: 3370,
                    27: 3450,
                    28: 3542,
                    29: 3634,
                    30: 3726,
                    31: 3818,
                    32: 3910,
                    33: 1002,
                    34: 4094,
                    35: 4186,
                    36: 4278,
                    37: 4370,
                    38: 4485,
                    39: 600,
                    40: 4715,
                    41: 4853,
                    42: 4991,
                    43: 5129,
                    44: 5290,
                    45: 5497,
                    46: 5727,
                    47: 5957,
                    48: 6199,
                    49: 6475,
                    50: 6785,
                    51: 7107,
                    52: 7475,
                    53: 7878,
                    54: 8303,
                    55: 8786,
                    56: 9315,
                    57: 9890,
                    58: 10557,
                    59: 11213,
                    60: 11868,
                    61: 12535,
                    62: 13455,
                    63: 14536,
                    64: 15698,
                    65: 17078
                }
            },
        },
        'completo': {
            'Pacífico Multisalud': {
                Dependientes: {
                    25: 3746,
                    26: 4132
                },
                Titulares: {
                    0: 3794,
                    1: 3794,
                    2: 3794,
                    3: 3794,
                    4: 3794,
                    5: 3794,
                    6: 3794,
                    7: 3794,
                    8: 3794,
                    9: 3794,
                    10: 3794,
                    11: 3794,
                    12: 3794,
                    13: 3794,
                    14: 3794,
                    15: 3794,
                    16: 3794,
                    17: 3794,
                    18: 3794,
                    19: 3794,
                    20: 3794,
                    21: 3794,
                    22: 3794,
                    23: 3794,
                    24: 3794,
                    25: 3794,
                    26: 4220,
                    27: 4416,
                    28: 4553,
                    29: 4721,
                    30: 4748,
                    31: 4811,
                    32: 4835,
                    33: 4885,
                    34: 4911,
                    35: 4975,
                    36: 4986,
                    37: 4996,
                    38: 5006,
                    39: 5017,
                    40: 5066,
                    41: 5480,
                    42: 5882,
                    43: 6403,
                    44: 6631,
                    45: 6883,
                    46: 7389,
                    47: 7697,
                    48: 7946,
                    49: 8207,
                    50: 8512,
                    51: 9081,
                    52: 9493,
                    53: 9809,
                    54: 10231,
                    55: 10686,
                    56: 11142,
                    57: 11596,
                    58: 12131,
                    59: 13041,
                    60: 14277,
                    61: 15124,
                    62: 16649,
                    63: 17911,
                    64: 19169,
                    65: 20798
                }
            },
        },
        'gamaAlta': {
            'Pacífico Med. Nacional': {
                Dependientes: {
                    17: 4370,
                    25: 4387,
                    26: 4862
                },
                Titulares: {
                    0: 4657,
                    1: 4657,
                    2: 4657,
                    3: 4657,
                    4: 4657,
                    5: 4657,
                    6: 4657,
                    7: 4657,
                    8: 4657,
                    9: 4657,
                    10: 4657,
                    11: 4657,
                    12: 4657,
                    13: 4657,
                    14: 4657,
                    15: 4657,
                    16: 4657,
                    17: 4657,
                    18: 4657,
                    19: 4657,
                    20: 4657,
                    21: 4657,
                    22: 4657,
                    23: 4657,
                    24: 4657,
                    25: 4657,
                    26: 5037,
                    27: 5318,
                    28: 5583,
                    29: 5899,
                    30: 6251,
                    31: 6466,
                    32: 6729,
                    33: 6893,
                    34: 7033,
                    35: 7147,
                    36: 7236,
                    37: 7326,
                    38: 7476,
                    39: 7638,
                    40: 7973,
                    41: 8269,
                    42: 8675,
                    43: 8982,
                    44: 9313,
                    45: 9546,
                    46: 10059,
                    47: 10423,
                    48: 10691,
                    49: 11091,
                    50: 11576,
                    51: 12441,
                    52: 13369,
                    53: 14136,
                    54: 14829,
                    55: 15458,
                    56: 15953,
                    57: 16884,
                    58: 17826,
                    59: 18814,
                    60: 19995,
                    61: 20991,
                    62: 22192,
                    63: 23704,
                    64: 25588,
                    65: 27348
                }
            },
        },
        'gamaSuperAlta': {
            'Pacífico Med. Internacional': {
                Dependientes: {
                    17: 8395,
                    25: 8459,
                    26: 9031
                },
                Titulares: {
                    0: 10854,
                    1: 10854,
                    2: 10854,
                    3: 10854,
                    4: 10854,
                    5: 10854,
                    6: 10854,
                    7: 10854,
                    8: 10854,
                    9: 10854,
                    10: 10854,
                    11: 10854,
                    12: 10854,
                    13: 10854,
                    14: 10854,
                    15: 10854,
                    16: 10854,
                    17: 10854,
                    18: 11088,
                    19: 11088,
                    20: 11088,
                    21: 11088,
                    22: 11088,
                    23: 11088,
                    24: 11088,
                    25: 11088,
                    26: 11119,
                    27: 11194,
                    28: 11427,
                    29: 11660,
                    30: 11883,
                    31: 12116,
                    32: 12338,
                    33: 12455,
                    34: 12688,
                    35: 12858,
                    36: 13027,
                    37: 13367,
                    38: 13716,
                    39: 14056,
                    40: 14395,
                    41: 14628,
                    42: 14967,
                    43: 15317,
                    44: 15423,
                    45: 15540,
                    46: 16218,
                    47: 17024,
                    48: 17829,
                    49: 18624,
                    50: 19652,
                    51: 20564,
                    52: 21709,
                    53: 23649,
                    54: 24910,
                    55: 26511,
                    56: 28450,
                    57: 30581,
                    58: 32383,
                    59: 34323,
                    60: 36104,
                    61: 39421,
                    62: 42856,
                    63: 45707,
                    64: 48453,
                    65: 51876
                }
            },
        }
    }
}
export default pacifico