const tasas = {
    "Rimac": {
      "Alto Riesgo": {
        0: 4.70,
        1: 4.87,
        2: 5.11,
        3: 5.37,
        4: 5.63,
        5: 5.91,
        6: 6.21,
        7: 6.52,
        8: 6.85,
        9: 7.19,
        10: 7.55,
        11: 7.93
      },
      "Mediano Riesgo": {
        0: 4.30,
        1: 4.46,
        2: 4.67,
        3: 4.90,
        4: 5.14,
        5: 5.39,
        6: 5.66,
        7: 5.94,
        8: 6.24,
        9: 6.54,
        10: 6.87,
        11: 7.21
      },
      "Bajo Riesgo 1": { 
        0: 3.60,
        1: 3.73,
        2: 3.91,
        3: 4.11,
        4: 4.31,
        5: 4.52,
        6: 4.74,
        7: 4.98,
        8: 5.23,
        9: 5.48,
        10: 5.76,
        11: 6.04
      },
      "Bajo Riesgo 2": {
        0: 3.45,
        1: 3.58,
        2: 3.75,
        3: 3.93,
        4: 4.13,
        5: 4.33,
        6: 4.55,
        7: 4.77,
        8: 5.01,
        9: 5.26,
        10: 5.52,
        11: 5.80
      }
    },
    "Pacifico": {
      "Alto Riesgo": {
        0: 3.325,
        1: 3.371,
        2: 3.546,
        3: 3.679,
        4: 3.881,
        5: 4.075,
        6: 4.216,
        7: 4.427,
        8: 4.653,
        9: 4.597,
        10: 4.951,
        11: 4.568,
        12: 4.951
      },
      "Mediano Riesgo": {
        0: 3.317,
        1: 3.623,
        2: 3.483,
        3: 3.794,
        4: 3.830,
        5: 3.963,
        6: 4.024,
        7: 4.124,
        8: 4.575,
        9: 4.475,
        10: 4.708,
        11: 4.472,
        12: 4.687
      },
      "Bajo Riesgo 1": {
        0: 2.894,
        1: 2.960,
        2: 3.042,
        3: 3.143,
        4: 3.240,
        5: 3.550,
        6: 3.802,
        7: 3.496,
        8: 4.120,
        9: 3.758,
        10: 3.889,
        11: 4.125,
        12: 4.358
      },
      "Bajo Riesgo 2": {
        0: 2.864,
        1: 2.872,
        2: 3.187,
        3: 3.248,
        4: 3.529,
        5: 3.759,
        6: 3.874,
        7: 3.450,
        8: 3.581,
        9: 3.758,
        10: 3.358,
        11: 3.892,
        12: 4.059
      }
    },
    "La Positiva": {
      "Alto Riesgo": {
        0: 4.040,
        1: 4.040,
        2: 4.320,
        3: 4.500,
        4: 4.500,
        5: 4.662,
        6: 4.841,
        7: 6.300,
        8: 5.373,
        9: 4.597,
        10: 6.218,
        11: 6.480
      },
      "Mediano Riesgo": {
        0: 4.300,
        1: 4.319,
        2: 4.500,
        3: 4.500,
        4: 4.662,
        5: 4.841,
        6: 5.184,
        7: 5.525,
        8: 5.877,
        9: 6.220,
        10: 6.220,
        11: 6.479},
      "Bajo Riesgo 1": {
        0: 3.533,
        1: 3.527,
        2: 3.967,
        3: 3.867,
        4: 3.918,
        5: 4.079,
        6: 4.326,
        7: 4.607,
        8: 4.896,
        9: 5.218,
        10: 5.796,
        11: 6.512},
      "Bajo Riesgo 2": {
        0: 3.450,
        1: 3.576,
        2: 3.748,
        3: 3.935,
        4: 4.129,
        5: 4.331,
        6: 4.548,
        7: 4.772,
        8: 5.011,
        9: 5.258,
        10: 5.520,
        11: 5.796}
    },
  }
export default tasas